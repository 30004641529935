import Vue from 'vue'
import App from './App.vue'
import {Icon,Col,Row,Input,Button} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1605414_q1lytdo0wc.js',
});

Vue.component('IconFont',IconFont);
Vue.component('AIcon',Icon);
Vue.component(Col.name,Col);
Vue.component(Row.name,Row);
Vue.component(Input.name,Input);
Vue.component(Button.name,Button);
new Vue({
  render: h => h(App),
}).$mount('#app')
